.react-joyride__tooltip button[title='Next'] {
  transition: all 0.3s ease;
  border: 1px solid !important;

  &:hover {
    color: var(--primary-main) !important;
    background-color: var(--primary-light) !important;
    border-color: var(--border-button-color) !important;
  }
}

.react-joyride__tooltip button[title='Last'] {
  transition: all 0.3s ease;
  border: 1px solid !important;

  &:hover {
    color: var(--primary-main) !important;
    background-color: var(--primary-light) !important;
    border-color: var(--border-button-color) !important;
  }
}

.react-joyride__tooltip button[title='Back'] {
  transition: all 0.3s ease;

  &:hover {
    text-decoration: underline;
    color: var(--primary-main) !important;
  }
}
